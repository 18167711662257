.padding {
    padding-top: 30px;
}

.paddingpw {
    padding-top: 30px;
    padding-left: 3px;
}


.margin {
    margin-top: 30px;
    margin-left: 5px;
}