
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
#team {
    background: #eee !important;
}
.btn-primary:hover,
.btn-primary:focus {
    background-color: #108d6f;
    border-color: #108d6f;
    box-shadow: none;
    outline: none;
}

.btn-primary {
    color: #fff;
    background-color: #007b5e;
    border-color: #007b5e;
}

section {
    padding: 60px 0;
}

 .section-title {
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: .08em;
    margin-left: 2%;
    /* color: #007b5e;
    text-transform: uppercase; */

    /* font-size: 30px; */
    /* font-weight: 600;
    -webkit-letter-spacing: .08em;
    -moz-letter-spacing: .08em;
    -ms-letter-spacing: .08em;
    letter-spacing: .08em; */
    font-size: 55px;
    white-space: nowrap;
    /* margin-top: 0; */
    color: #00A8E8;
    text-shadow: 3px 3px 0 black, 1px 1px 0 black, 2px 2px 0 black;
    @media screen and (max-width: 1024px) {
        display: none;
    }

}

 .card {
    border: none;
    background: #ffffff;
    border-radius: 2%;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 300px;
    width: 250px;
}

.backside .card a {
    font-size: 18px;
    color: #fa6 !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #000 !important;
    white-space: nowrap;
    font-style: italic;
    font-size: 20px;
    font-family: "Beaufort for LOL", serif;
    font-weight: 800;
    letter-spacing: 0.15em;
    text-overflow: ellipsis;
    transition: transform 300m
}
.frontside .card .card-title{
    padding-bottom: 10px;
    margin-top: 5px; 
}
.card-title{
    text-transform: uppercase;
}

.frontside .card   {

    border-top-left-radius: 3%;
    border-top-right-radius: 3%;
    /* border-radius: 12%; */
}
.frontside .card  img {
    width: 100%;
    height: 277px;
    /* height: 100%; */
    /* border-radius: 50%; */
    border-top-left-radius: 3%;
    border-top-right-radius: 3%;
    /* border-radius: 12%; */
}
h4,h5,h6{
    color: black;
    margin-bottom: 5px;
    margin-top:5px;
    /* font-size: 15px; */
}