@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');

.glitching {
  padding-left: -100px;
}

.glitch {
  background: 50% 100% / 50% 50% no-repeat
    radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 10vw;
  padding-left: 100px;
  font-family: "Source Sans Pro", sans-serif;
  animation: reveal 3000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;

  @keyframes reveal {
    80%{
      letter-spacing: 8px;
    }
    100% {
      background-size: 300% 300%;
    }
  }
  @keyframes glow {
    40% {
      text-shadow: 0 0 8px #fff;
    }
  }
}
$base: 0.6rem;

.container {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 height: 100vh;
}

.chevron {
  width: $base * 8;
  height: $base * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
  margin-left: 395px;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
 content: '';
 position: absolute;
 height: 100%;
 width: 100%;
 background: #ff5c8a;
}

.chevron:before {
 top: 10px;
 left: -41px;
 transform: skewY(30deg);
}

.chevron:after {
 top: 10px;
 right: -35px;
 width: 100%;
 transform: skewY(-30deg);
}

@keyframes move-chevron {
 25% {
  opacity: 1;
  }
 33.3% {
  opacity: 1;
  transform: translateY($base * 3.8);
 }
 66.6% {
  opacity: 1;
  transform: translateY($base * 7);
 }
 100% {
  opacity: 0;
  transform: translateY($base * 8) scale(0.5);
 }
}

.line-1{
  position: relative;
  top: 0%;
  right: 20%;
  width: 24em;
  flex-direction: column;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
animation: typewriter 2s steps(44) 1s 1 normal both,
           blinkTextCursor 500ms steps(44) 13 normal;
}
@keyframes typewriter{
from{width: 0;}
to{width: 24em;}
}
@keyframes blinkTextCursor{
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}

/* Animation 2 */
.anim-typewriter2{
animation: typewriter 2s steps(44) 3s 1 normal both,
           blinkTextCursor 500ms steps(44) 4s 5 normal;
}
@keyframes typewriter{  
from{width: 0;}
to{width: 24em;}
}
@keyframes blinkTextCursor{
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}



div {
  color: white;
}

.contentDivider {
  right: 20%;
  width: 24em;
  height: 7em;
}


.infoTextTitle {
  font-size: 150%;
  font-weight: 600;
}

.imgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128,128,128,0.75);
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.loadInAnim {
  animation: fadeInAnimation ease 1.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.series-resize-val {
  width: 125px;
  height: auto;
}

.series-resize-tft {
  width: 90px;
  height: auto;
}

.series-resize-osu {
  width: 80px;
  height: auto;
}

.series-resize-tekken8 {
  width: 95px;
  height: 30px;
}

.series-resize-pokemon {
  width: 81px;
  height: 81px;
}


.sett {
  text-decoration: none;
}

.none {
  text-decoration: none;
}