tbody.profileTable td:hover{
    text-align: left;
    font-weight: normal;
    position: sticky;
    background-color: rgb(43, 38, 38,0.7) !important;
    left: 0px;
    /* top: 0; */
    z-index: 40;
    /* border-bottom: 2px solid goldenrod;  */
  }
  .champ-button{
    float: left;
    width: 82px;
    height: 88px;
    background: #24262D;
    color: #fff;
    margin-bottom: 5px;
    border: 3px solid transparent;
    
  }
  .buttonText{
    text-align: center;
    padding: 6px;
    font-size: 12px;
    color: #D0D7DE;
    font-weight: 500;
    padding-top: 18px;
    cursor: pointer;
  }
  .customButton{
    /* position: absolute; */
    /* top: 10px; */
    width: 82px;
    bottom: auto;
    /* left: 0px; */
  }
  .champ-button:active {
    border-left: 3px solid #3A74FA;
}