@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.loadInAnim {
    animation: fadeInAnimation ease 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.linkHover:after {
        content: "";
        display: block;
        position: relative;
        width: 0% ;
        border-bottom: 5px solid white !important;
        transition: width .5s ease, background-color .5s ease;
    }

    :hover:after {
        width: 100%;
    }

.paddingBottom {
    padding-top: 50px;
    padding: 40px 0;
}

.mouseText {
    background-color: white;
    border-style: double;
}

    

.infinite-scroll-component {
    background-color: #000;
    scrollbar-color: rgba(0, 0, 0, 0.2) #ffff;
    scrollbar-face-color:rgba(0, 0, 0, 0.2);
    }
    .infinite-scroll-component::-webkit-scrollbar {
    width: 10px!important;
    height: 10px!important;
    }

    .infinite-scroll-component::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    }

    .infinite-scroll-component::-webkit-scrollbar-thumb {
    background: #0060FF;
    border-radius: 10px;
    }

    .infinite-scroll-component::-webkit-scrollbar-thumb:hover {
    background:#3380ff;
    }


a {
    color: white;
}