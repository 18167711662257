.img-resizeTeamsss {
    width: 100%;
    height: auto;
}

/* 
.table.t-h tr.snip:first-child {
    background-color: red !important;

} */
.infiniteScrollClass th{
    top: 0;
    position: sticky;
}
ebkit-scrollbar-track {
    background-color: darkgrey;
}
.iconResize {
    width: 85%;
    height: auto;
    margin-top: 70px;
    margin-left: 8px;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.itcLoadIn {
    animation: fadeInAnimation ease 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.tableStripped tr:nth-child(even) {
    background-color: rgb(0, 0, 0,0.55);
  }
tr.th-head th {
    background: #101010;
    border-top: none;
    /* color : rgb(247, 179, 24); */
    color: white;
    /* border-left: solid 1px black; */
    /* border-right: solid 1px #786F6D;
    border-bottom: solid 1px #786F6D; */
}
/* scroll bar customization */
/* width */
.customScroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
.customScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px black;
    border-radius: 20px;
  }
  
  /* Handle */
 .customScroll::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 2px;
  }
  @media screen and  (min-width: 768px) {
    .customScroll {
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .customScroll:hover {
        overflow-x: scroll;
        overflow-y: scroll;
    }
    }
  @media screen and  (max-width: 768px) {
.customScroll {
    overflow-x: scroll;
    overflow-y: scroll;
}

}
  /* .zui-table {
    border: solid 1px #DDEEEE;
    border-collapse: collapse;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
}
.zui-table thead th {
    background-color: #DDEFEF;
    border: solid 1px #DDEEEE;
    color: #336B6B;
    padding: 10px;
    text-align: left;
    text-shadow: 1px 1px 1px #fff;
} */
/* .rt-tr .rt-td:first-child {
    background: yellow;
    position: fixed;
    padding: 7px 7px;
    padding-left: 0;
  } */

.zui-table-highlight tbody tr:hover {
    background-color: rgb(98, 70, 4) !important ;

}
/* .zui-table-horizontal tbody td {
    border-left: none;
    border-right: none;
} */

/* Place holder color */
.placeholderColor::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }
  
  .placeholderColor:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: white;
  }
  
  .placeholderColor::-ms-input-placeholder { /* Microsoft Edge */
   color: white;
  }


