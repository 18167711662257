@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.loadInAnim {
    animation: fadeInAnimation ease 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

html {
  height: 100vh;
}

body {
  min-height: 100vh;
  padding: 0;
}

.clickToCopy {
  color: #FF9508;
}

.clickToCopy:hover {
  color: #C36F00;
}