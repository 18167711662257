.twitch .twitch-video {
    padding-top: 56.25%;
    position: relative;
    height: 0;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.twitch .twitch-video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.twitch .twitch-chat {
    height: 400px;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
.twitch .twitch-chat iframe {
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 850px) {
    .twitch {
      position: relative;
    }
  
    .twitch .twitch-video {
      width: 75%;
      padding-top: 42.1875%;
    }
  
    .twitch .twitch-chat {
      width: 25%;
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }
}

