.img-resizeTeam {
    width: 80px;
    height: auto;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.itcLoadIn {
    animation: fadeInAnimation ease 1.2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

body {
    background-color: #141D24 !important; 
}