
.canvas{
    background-color: rgb(60, 62, 72);
    /* height: 320px; */
    /* width:29%; */
    padding: 8px 8px;
    /* float: left; */
    border-radius: 4%;

}
@media only screen and (max-width: 800px) {
    .canvas{
        background-color: rgb(60, 62, 72);
        /* height: 320px; */
        /* width:29%; */
        padding: 8px 8px;
        /* float: left; */
        border-radius: 4%;
        margin-top: 20px;
    }
  }
.start-heading{
    font-weight: 700;
    font-size: 18px;
    /* padding-bottom: 5px; */
    color: #fff;
    text-transform: uppercase;
    /* position: flex; */
}
.avg-canvas{
    background-color: rgb(60, 62, 72);
    /* height: 320px; */
    /* width:29%; */
    padding: 8px 8px;
    /* float: right; */
    /* margin-left: 12px; */
    border-radius: 4%;

}
@media only screen and (max-width: 800px) {
    .avg-canvas{
        background-color: rgb(60, 62, 72);
        /* height: 320px; */
        /* width:29%; */
        padding: 8px 8px;
        margin-top: 15px;
        border-radius: 4%;
    
    }
  }

.win-card{
    background: #3C3E48;
    color: #A6B0BD !important;
    /* border-radius: 3px; */
    padding: 8px 12px;
    margin: 0 8px 16px;
    margin-left: 12px;
    /* margin-top: 30px; */

    height: 320px;
    /* width: 32%; */
    /* float: right; */
    margin-bottom: 50px;
    border-radius: 4%;

}
@media only screen and (max-width: 800px) {

    .win-card{
        background: #3C3E48;
        color: #A6B0BD !important;
        /* border-radius: 3px; */
        padding: 8px 12px;
        margin: 0 8px 16px;
        margin-left: 12px;
        margin-top: 12px;
        margin-bottom: 15px;
        border-radius: 4%;
    
    }
  }
.win-heading{
    font-weight: 700;
    font-size: 13px;
    padding-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 6px;
}
.win-percentage{
    float: left;
    width: 60%;
    font-size: 34px;
    color: #fff;
    line-height: 0.75em;
    text-align: center;
    margin-bottom: 15px;
}
.total-stat {
    font-size: 13px;
    padding: 8px 0;
    border-bottom: 1px dotted #484E5B;
    display: flex;
}
.total-label {
    float: left;
    color: #A6B0BD;
    width: 60%;
    font-weight: bold;
    text-transform: uppercase;
}
.total-value {
    float: left;
    width: 40%;
    color: #A6B0BD;
    text-align: right;
}
.clearfix {
    clear:both;
}
.kill-percentage{
    float: left;
    width: 60%;
    font-size: 30px;
    color: #fff;
    line-height: 0.75em;
    text-align: right;
}
.kill-card{
    /* background: #3C3E48;
    border-radius: 3px;
    padding: 8px 12px;
    margin: 0 8px 16px;
    margin-bottom: 0px;
    margin-left: 5px;
    border-radius: 4%;
    float: left; */
    background: #3C3E48;
    color: #A6B0BD !important;
    /* border-radius: 3px; */
    padding: 8px 12px;
    margin-top: 15px;
    /* margin-left: 12px; */
    /* margin-top: 30px; */

    /* height: 320px; */
    /* width: 32%; */
    /* float: right; */
    margin-bottom: 50px;
    border-radius: 4%;
    
}
@media only screen and (max-width: 800px) {
    .kill-card{
        background: #3C3E48;
    color: #A6B0BD !important;
    /* border-radius: 3px; */
    padding: 8px 12px;
    margin: 0 8px 16px;
    margin-left: 12px;
    /* margin-top: 30px; */

    /* height: 320px; */
    /* width: 32%; */
    /* float: right; */
    margin-bottom: 50px;
    border-radius: 4%;
    }
  }

  .btn-group .button-1,.button-2{
    background-color:rgb(98, 70, 4);
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    cursor: pointer;
    float: left;
}

.button-1{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.button-2{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.btn-group .button-1:hover ,.button-2:hover {
    background-color: #F1B317;
  }

  .profile-title {
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: .08em;
    margin-left: 2%;

    font-size: 55px;
    white-space: nowrap;
    /* margin-top: 0; */
    color: #00A8E8;
    text-shadow: 3px 3px 0 black, 1px 1px 0 black, 2px 2px 0 black;
    @media screen and (max-width: 1024px) {
        display: none;
    }

}
@media only screen and (max-width: 800px) {
    .profile-title {
        font-size: 40px;
    }
}
